import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Gelaender from "../queries/gelaender"

import ServiceAndProcedure from "../components/serviceAndProcedure"

const Index = () => {
  const { sliderImage } = useStaticQuery(
    graphql`
      query {
        sliderImage: file(relativePath: { eq: "slider/header_gelaender.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 85
              placeholder: BLURRED
              formats: [AUTO, WEBP, JPG]
            )
          }
        }
      }
    `
  )
  const image = getImage(sliderImage)
  const bgImage = convertToBgImage(image)

  return (
    <Layout spacing="true">
      <Seo
        title="Geländer"
        description="Ein hochwertiges Geländer trägt nicht nur zur Sicherheit bei, sondern ist auch ein Blickfang oder auch Sichtschutz Ihres Hauses."
      />

      <div className="flex w-full h-screen">
        <BackgroundImage
          {...bgImage}
          style={{ width: "100%" }}
          className="h-full w-full flex items-center"
        >
          <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 text-white mt-48 md:mt-72">
            <h1 className="text-4xl md:text-5xl font-bold mb-1.5">Geländer</h1>
            <p className="max-w-4xl text-xl mt-1">
              Ein hochwertiges Geländer trägt nicht nur zur Sicherheit bei,
              sondern ist auch ein Blickfang oder Sichtschutz Ihres Hauses.
            </p>
          </div>
        </BackgroundImage>
      </div>
      <div className="container max-w-screen-lg xl:max-w-screen-xl mx-auto px-6 bg-white">
        <div className="grid gap-5 grid-cols-1 lg:grid-cols-2">
          <div className="flex items-center justify-center lg:order-last">
            <img
              src="/zeichnungen/gelaender.svg"
              className="block h-56"
              alt="Technische Zeichnung eines Geländers"
            />
          </div>
          <div className="flex items-center">
            <p>
              Wir bieten Ihnen eine große Auswahl verschiedener Grundmodelle an,
              die Sie ganz individuell mit verschiedenen Materialien (Stahl,
              Edelstahl, Aluminium etc.) nach Ihren Vorstellungen gestalten
              können. Wir fertigen die einzelnen Modelle dabei auf den
              Millimeter genau an. Sie bestimmen die Maße, Form, Spitzen und
              Beschichtungsart Ihres neuen Geländers. Metallgeländer sind zudem
              in jeder Hinsicht pflegeleicht und unempfindlich, da Sie allen
              natürlichen Witterungen standhalten.
            </p>
          </div>
        </div>
      </div>
      <ServiceAndProcedure />
      <div className="pb-16">
        <h2 className="w-full text-4xl md:text-5xl text-center mb-6 text-gray-800">
          Galerie
        </h2>
        <Gelaender />
      </div>
    </Layout>
  )
}

export default Index
